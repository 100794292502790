export default [
  {
    title: 'Start',
    icon: 'ChevronDownIcon',
    disabled: true,
  },
  {
    title: 'Inicio',
    route: 'central',
    icon: 'HomeIcon',
  },
  {
    title: 'Grupo',
    icon: 'ChevronDownIcon',
    disabled: true,
  },
  {
    title: 'Cadastrar Grupo',
    route: 'cadastrar-grupo',
    icon: 'UserIcon',
  },
  {
    title: 'Meus Grupos',
    route: 'meus-grupos',
    icon: 'ListIcon',
  },
  {
    title: 'Funções',
    route: 'funcoes',
    icon: 'ListIcon',
  },

  {
    title: 'Financeiro',
    icon: 'ChevronDownIcon',
    disabled: true,
  },
  // {
  //   title: 'Pagar',
  //   route: 'pagar',
  //   icon: 'DollarSignIcon',
  // },
  // {
  //   title: 'Renovar',
  //   route: 'renovar',
  //   icon: 'DollarSignIcon',
  // },
  {
    title: 'Historico',
    route: 'historico',
    icon: 'DollarSignIcon',
  },

  // {
  //   title: 'missões',
  //   icon: 'ChevronDownIcon',
  //   disabled: true,
  // },
  // {
  //   title: 'Missões',
  //   route: 'missoes',
  //   icon: 'AwardIcon',
  // },
  // {
  //   title: 'Missões Concluidas',
  //   route: 'missoes-concluidas',
  //   icon: 'AwardIcon',
  // },
  // {
  //   title: 'Ranking',
  //   route: 'ranking',
  //   icon: 'AwardIcon',
  // },

  // {
  //   title: 'Trocar Pontos',
  //   route: 'trocar-pontos',
  //   icon: 'RefreshCwIcon',
  // },
]
